import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/screens/Home/Home';
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, AuthProvider } from './components/firebase/AuthContext';
import LoginScreen from './components/LoginScreen';
import { Provider } from 'react-redux';
import store from './services/store';
import useThemeColors from './components/colors/useThemeColors';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  const [user, setUser] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const [themePreference, setThemePreference] = useState('system');

  const themeColors = useThemeColors(themePreference);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (initializing) {
        setInitializing(false);
      }
    });

    return () => unsubscribe();
  }, [initializing]);

  const updateThemePreference = (preference) => {
    setThemePreference(preference);
    // Update theme preference in localStorage or any other storage mechanism
  };

  return (
    <Router>
      <Provider store={store}>
        <AuthProvider
          user={user}
          setUser={setUser}
          initializing={initializing}
          updateThemePreference={updateThemePreference}
          themePreference={themePreference}
          setThemePreference={setThemePreference}
          themeColors={themeColors}
        >
          <div className="App">
            {/*<Header />*/}
            <main className="main-content">
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                {/*<Route path="/login" element={<LoginScreen />} />*/}
              </Routes>
            </main>
            <Footer />
          </div>
        </AuthProvider>
      </Provider>
    </Router>
  );
}

export default App;