import { configureStore } from '@reduxjs/toolkit';
import latestTitleListReducer from '../components/screens/Home/latestTitleListSlice';
/*import trendingTitleListReducer from '../components/screens/HomeScreen/trendingTitleListSlice';
import preferredCategoriesTitlesReducer from '../components/screens/HomeScreen/preferredCategoriesTitlesSlice';
import latestTitlesByCategoryReducer from '../components/screens/ExploreScreen/latestTitlesByCategorySlice';
import recommendedTitlesByCategoryReducer from '../components/screens/ExploreScreen/recommendedTitlesByCategorySlice';
import trendingTitlesByCategoryReducer from '../components/screens/ExploreScreen/trendingTitlesByCategorySlice';
import recommendedTitlesReducer from '../components/screens/HomeScreen/recommendedTitlesSlice';
import savedTitlesReducer from './savedTitlesSlice';
import inProgressTitlesReducer from './inProgressTitlesSlice';
import finishedTitlesReducer from './finishedTitlesSlice';
import categoriesReducer from './categoriesSlice';
import similarTitlesReducer from '../components/common/similarTitlesSlice';
*/


const store = configureStore({
  reducer: {
    latestTitleList: latestTitleListReducer,
    /*
    trendingTitleList: trendingTitleListReducer,
    preferredCategoriesTitles: preferredCategoriesTitlesReducer,
    latestTitlesByCategory: latestTitlesByCategoryReducer,
    recommendedTitlesByCategory: recommendedTitlesByCategoryReducer,
    trendingTitlesByCategory: trendingTitlesByCategoryReducer,
    recommendedTitles: recommendedTitlesReducer,
    savedTitles: savedTitlesReducer,
    inProgressTitles: inProgressTitlesReducer,
    finishedTitles: finishedTitlesReducer,
    categories: categoriesReducer,
    similarTitles: similarTitlesReducer,
    // Add other reducers if needed*/
  },
});

export default store;