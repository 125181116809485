import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GlobalApi from '../../GlobalApi';

export const fetchLatestTitleList = createAsyncThunk(
  'latestTitleList/fetchLatestTitleList',
  async () => {
    const response = await GlobalApi.getTitleList();
    if (response.titleLists) {
      const sortedTitleLists = response.titleLists.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
      return sortedTitleLists.slice(0, 50);
    }
    return [];
  }
);

const latestTitleListSlice = createSlice({
  name: 'latestTitleList',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLatestTitleList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLatestTitleList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchLatestTitleList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default latestTitleListSlice.reducer;