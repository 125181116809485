import React, { createContext, useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

// Your Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyBZlJV93pmIwQxGQz67_KJo3KKbZn8RWJM',
    authDomain: 'histochat-23336.firebaseapp.com',
    projectId: 'histochat-23336',
    // Add other configuration properties as needed
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export const AuthContext = createContext();

export const AuthProvider = ({ children, themeColors }) => {
    const [user, setUser] = useState(null);
    const [isNewUser, setIsNewUser] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
        });

        return () => unsubscribe();
    }, []);

    const signInWithEmail = async (email, password) => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            console.error('Error signing in with email:', error);
            throw error;
        }
    };

    const signUpWithEmail = async (name, email, password) => {
        try {
            const { user } = await createUserWithEmailAndPassword(auth, email, password);
            await setDoc(doc(db, 'users', user.uid), {
                name,
                email,
            });
            setIsNewUser(true);
        } catch (error) {
            console.error('Error signing up with email:', error);
            throw error;
        }
    };

    const signInWithGoogle = async () => {
        try {
          const provider = new GoogleAuthProvider();
          const result = await signInWithPopup(auth, provider);
          // User signed in successfully
          const user = result.user;
          console.log('User signed in:', user);
          // Perform any additional actions or state updates here
        } catch (error) {
          console.error('Error with Google Sign-In:', error);
          throw error;
        }
      };

    const logout = async () => {
        try {
            await signOut(auth);
            setUser(null);
        } catch (error) {
            console.error('Error logging out:', error);
            throw error;
        }
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                logout,
                signInWithGoogle,
                signUpWithEmail,
                signInWithEmail,
                isNewUser,
                setIsNewUser,
                themeColors
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export { auth, db, app };