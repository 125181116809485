import React from 'react';
import './CustomArrows.css';

export const NextArrow = ({ onClick }) => (
  <div className="arrow next" onClick={onClick}>
    <i className="fas fa-chevron-right" />
  </div>
);

export const PrevArrow = ({ onClick }) => (
  <div className="arrow prev" onClick={onClick}>
    <i className="fas fa-chevron-left" />
  </div>
);
