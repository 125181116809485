import React from 'react';
import './TermsAndConditions.css';

function TermsAndConditions() {
  return (
    <div className="terms-and-conditions">
      <h1>Terms and Conditions for Histo</h1>

      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>By downloading, installing, or using the Histo mobile application ("App"), you agree to be bound by these Terms and Conditions. If you disagree with any part of these terms, you may not use our App.</p>
      </section>

      <section>
        <h2>2. Description of Service</h2>
        <p>Histo provides personalized audio biographies and interactive quizzes about historical figures. We offer both free and paid subscription services.</p>
      </section>

      <section>
        <h2>3. User Accounts</h2>
        <p>You may need to create an account to use some features of the App. You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.</p>
      </section>

      <section>
        <h2>4. Subscription and Payments</h2>
        <p>Paid subscriptions provide ad-free access and unlimited use of the App. Subscriptions automatically renew unless cancelled at least 24 hours before the end of the current period. You can manage and cancel your subscription through your app store account settings.</p>
      </section>

      <section>
        <h2>5. Intellectual Property Rights</h2>
        <p>The App and its original content, features, and functionality are owned by Histo and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
      </section>

      <section>
        <h2>6. User Conduct</h2>
        <p>You agree not to use the App:</p>
        <ul>
          <li>In any way that violates any applicable national or international law or regulation.</li>
          <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter," "spam," or any other similar solicitation.</li>
          <li>To impersonate or attempt to impersonate the App, a Histo employee, another user, or any other person or entity.</li>
          <li>In any way that could disable, overburden, damage, or impair the App or interfere with any other party's use of the App.</li>
        </ul>
      </section>

      <section>
        <h2>7. Limitation of Liability</h2>
        <p>In no event shall Histo, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the App.</p>
      </section>

      <section>
        <h2>8. Disclaimer</h2>
        <p>Your use of the App is at your sole risk. The App is provided on an "AS IS" and "AS AVAILABLE" basis. The App is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>
      </section>

      <section>
        <h2>9. Governing Law</h2>
        <p>These Terms shall be governed and construed in accordance with the laws of Spain, without regard to its conflict of law provisions.</p>
      </section>

      <section>
        <h2>10. Changes</h2>
        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect.</p>
      </section>

      <section>
        <h2>11. Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us at contact@histochat.com.</p>
      </section>
    </div>
  );
}

export default TermsAndConditions;