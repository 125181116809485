import useColorScheme from './useColorScheme';

const useThemeColors = (themePreference) => {
    const colorScheme = useColorScheme();

    const darkColors = {
        backgroundColor: 'black',
        textColor: 'white',
        placeholderTextColor: 'gray',
        borderColor: 'gray',
        statusBarStyle: 'light-content',
        statusBarColor: 'black',
        subtextColor: '#CCC',
        // Add more colors specific to the dark theme
        modalBackgroundColorSettings: 'rgba(0, 0, 0, 0.5)',
        modalContentBackgroundColor: 'black',
        modalTitleColor: 'white',
        modalMessageColor: 'white',
        modalButtonBackgroundColor: 'white',
        modalButtonTextColor: 'black',
        cancelButtonBackgroundColor: 'gray',
        successBackgroundColor: 'rgba(0, 0, 0, 0.8)',
        successContentBackgroundColor: 'black',
        successTextColor: 'white',
        successButtonBackgroundColor: 'white',
        successButtonTextColor: 'black',
        // ChatScreen Colors
        headerBackground: 'black',
        chatBackground: '#1c1c1c',
        chatTextColor: 'white',
        chatTimestampColor: 'gray',
        chatLastMessageColor: 'gray',
        noChatTextColor: 'white',
        noChatSubtextColor: 'gray',
        // ...
        searchBarBackgroundColor: '#333333',
        searchBarPlaceholderColor: '#CCCCCC',
        searchBarIconColor: 'white',
        listItemBackgroundColor: '#1A1A1A',
        listItemTitleColor: 'white',
        listItemDescriptionColor: '#CCCCCC',
        listItemDetailsColor: '#CCCCCC',
        // ...
        userMessageBackground: '#0f0f0f',
        userMessageBorder: '#1c1c1c',
        botMessageBackground: '#1c1c1c',
        messageTextColor: 'white',
        inputBackground: '#1c1c1c',
        inputBorder: 'black',
        inputTextColor: 'white',
        sendButtonBackground: '#1c1c1c',
        sendButtonBorder: 'black',
        typingIndicatorColor: 'gray',
        //
        inactiveColor: 'gray',
        // ...
        audioPlayerBackground: 'black',
        audioPlayerText: 'white',
        audioPlayerButtonBackground: 'white',
        audioPlayerButtonText: 'black',
        audioPlayerSliderMinimumTrackColor: '#FFFFFF',
        audioPlayerSliderMaximumTrackColor: 'rgba(255, 255, 255, 0.3)',
        audioPlayerSliderThumbColor: '#FFFFFF',
        audioPlayerScriptBackground: 'rgba(255, 255, 255, 0.1)',
        //
        tabBarBackground: 'black',
        //
        skeletonBackground: '#1c1c1c',
        skeletonGradient: ['#1c1c1c', '#2c2c2c', '#1c1c1c'],
        // titleListItems
        titleCategorybackgroundColor: 'rgba(245, 222, 179, 0.3)',
        titleCategoryColor: 'wheat',
        // Forgot Password
        forgotPasswordBackground: 'black',
        forgotPasswordText: 'white',
        forgotPasswordPlaceholderText: '#888',
        forgotPasswordButtonBackground: 'white',
        forgotPasswordButtonText: 'black',
        forgotPasswordErrorText: 'red',
        forgotPasswordSuccessText: 'green',
        // ...
        buttonBackgroundColor: 'white',
        buttonTextColor: 'black',
        separatorColor: '#555',
        providerButtonBackground: '#333',
        /// titleDetails
        readButtonbackgroundColor: 'rgba(230, 230, 230, 0.1)',
        readButtonborderColor: 'rgba(230, 230, 230, 0.3)',
        infoRowborderColor: '#444444',
        categoryTextbackgroundColor: '#333333',
        // minimizedPlayer
        modalBackgroundColor: 'rgba(0, 0, 0, 0.9)',

    };

    const lightColors = {
        backgroundColor: 'white',
        textColor: 'black',
        placeholderTextColor: 'darkgray',
        borderColor: 'black',
        statusBarStyle: 'dark-content',
        statusBarColor: 'white',
        subtextColor: '#333',
        // Add more colors specific to the light theme
        modalBackgroundColorSettings: 'rgba(0, 0, 0, 0.5)',
        modalContentBackgroundColor: 'white',
        modalTitleColor: 'black',
        modalMessageColor: 'black',
        modalButtonBackgroundColor: 'black',
        modalButtonTextColor: 'white',
        cancelButtonBackgroundColor: 'gray',
        successBackgroundColor: 'rgba(255, 255, 255, 0.8)',
        successContentBackgroundColor: 'white',
        successTextColor: 'black',
        successButtonBackgroundColor: 'black',
        successButtonTextColor: 'white',
        // ... ChatScreen Colors
        headerBackground: 'white',
        chatBackground: '#f0f0f0',
        chatTextColor: 'black',
        chatTimestampColor: 'gray',
        chatLastMessageColor: 'gray',
        noChatTextColor: 'black',
        noChatSubtextColor: 'gray',
        // ...
        searchBarBackgroundColor: '#F0F0F0',
        searchBarPlaceholderColor: '#999999',
        searchBarIconColor: 'black',
        listItemBackgroundColor: '#FFFFFF',
        listItemTitleColor: 'black',
        listItemDescriptionColor: '#666666',
        listItemDetailsColor: '#999999',
        // ...
        userMessageBackground: '#e0e0e0',
        userMessageBorder: '#ccc',
        botMessageBackground: '#f0f0f0',
        messageTextColor: 'black',
        inputBackground: '#f0f0f0',
        inputBorder: '#ccc',
        inputTextColor: 'black',
        sendButtonBackground: '#e0e0e0',
        sendButtonBorder: '#ccc',
        typingIndicatorColor: 'gray',
        //
        inactiveColor: 'gray',
        // ...
        audioPlayerBackground: 'white',
        audioPlayerText: 'black',
        audioPlayerButtonBackground: 'black',
        audioPlayerButtonText: 'white',
        audioPlayerSliderMinimumTrackColor: '#000000',
        audioPlayerSliderMaximumTrackColor: 'rgba(0, 0, 0, 0.3)',
        audioPlayerSliderThumbColor: '#000000',
        audioPlayerScriptBackground: 'rgba(0, 0, 0, 0.1)',
        //
        tabBarBackground: 'white',
        ///
        skeletonBackground: '#e0e0e0',
        skeletonGradient: ['#e0e0e0', '#f0f0f0', '#e0e0e0'],
        // titleListItems
        titleCategorybackgroundColor: 'rgba(0, 0, 0, 0.2)',
        titleCategoryColor: 'black',
        // Forgot Password
        forgotPasswordBackground: 'white',
        forgotPasswordText: 'black',
        forgotPasswordPlaceholderText: '#888',
        forgotPasswordButtonBackground: 'black',
        forgotPasswordButtonText: 'white',
        forgotPasswordErrorText: 'red',
        forgotPasswordSuccessText: 'green',
        // ...
        buttonBackgroundColor: 'black',
        buttonTextColor: 'white',
        separatorColor: '#CCC',
        providerButtonBackground: '#EEE',
        /// titleDetails
        readButtonbackgroundColor: 'rgba(0, 0, 0, 0.1)',
        readButtonborderColor: 'rgba(0, 0, 0, 0.3)',
        infoRowborderColor: '#CCCCCC',
        categoryTextbackgroundColor: '#F0F0F0',
        // minimizedPlayer
        modalBackgroundColor: 'rgba(255, 255, 255, 0.9)',
    };

    if (themePreference === 'dark') {
        return darkColors;
    } else if (themePreference === 'light') {
        return lightColors;
    } else {
        // Use system theme colors
        return colorScheme === 'dark' ? darkColors : lightColors;
    }
};

export default useThemeColors;
