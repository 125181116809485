import { request, gql } from 'graphql-request';

const MASTER_URL = 'https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/clsi0wnr92nvq01wj9pfeknpp/master'

const getCategories = async () => {
  const query = gql`
    query MyQuery {
        categories {
            id
            name
            icon {
                url
            }
        }
    }
    `
  const result = await request(MASTER_URL, query)
  return result
}

const getTitleListOptimized = async (page, pageSize) => {
  const query = gql`
    query getTitleList($skip: Int!, $first: Int!) {
      titleLists(skip: $skip, first: $first, orderBy: createdAt_DESC) {
        id
        name
        category {
          name
        } 
        categoryMulti {
          name
        }
        description
        durationAudio
        image {
          url
        }
        longDescription       
        scriptText
        script {
          html
          markdown
          raw
          text
        }
        audio {
          fileName
          url
        }
        multiplechoicequestion {
          id
          question
          choice 
          correctAnswer
          explanation
          whole
        }
        tags {
          tag
          type
        }
        subCategories {
          id
          subCategoryName
        }
      }
    }
  `;

  const skip = (page - 1) * pageSize;
  const result = await request(MASTER_URL, query, { skip, first: pageSize });
  return result;
};


const getTitleList = async () => {
  const query = gql`
    query getTitleList {
      titleLists(first: 100, orderBy: createdAt_DESC) {
        id
        name
        category {
          name
        } 
        categoryMulti {
          name
        }
        description
        durationAudio
        image {
          url
        }
        longDescription       
        scriptText
        script {
          html
          markdown
          raw
          text
        }
        audio {
          fileName
          url
        }
        multiplechoicequestion(first: 50) {
          id
          question
          choice 
          correctAnswer
          explanation
          whole
        }
        tags {
          tag
          type
        }
        subCategories {
          id
          subCategoryName
        }
      }
    }`
  const result = await request(MASTER_URL, query)
  return result
}




const getPeopleCategory = async () => {
  const query = gql`
    query getPeopleCategory {
      categories(last: 40) {
        id
        name
        icon {
          url
        }
        type
      }
    }
  `;
  const result = await request(MASTER_URL, query);
  return result;
}

const getOrganizationCategory = async () => {
  const query = gql`
    query getOrganizationCategory {
      categories(where: {type: "Organization"}) {
        id
        name
        icon {
          url
        }
        type
      }
    }
  `;
  const result = await request(MASTER_URL, query);
  return result;
}

const getTitleListByCategory = async (categoryName) => {
  const query = gql`
    query getTitleListByCategory($categoryName: String!) {
      titleLists(where: {categoryMulti_some: {name: $categoryName}}, first: 100, orderBy: createdAt_DESC) {
        id
        name
        categoryMulti {
          name
        }
        description
        durationAudio
        image {
          url
        }
        longDescription
        scriptText
        script {
          html
          markdown
          raw
          text
        }
        audio {
          fileName
          url
        }
        tags {
          tag
          type
        }
        subCategories {
          id
          subCategoryName
        }
        multiplechoicequestion {
          whole
        }
      }
    }`
  const variables = { categoryName };
  const result = await request(MASTER_URL, query, variables);
  return result;
};


export default {
  getCategories,
  getTitleList,
  getPeopleCategory,
  getOrganizationCategory,
  getTitleListByCategory,
  getTitleListOptimized
}


/*const getTitleList = async () => {
  const query = gql`
    query getTitleList  {
        titleLists {
          id
          name
          category {
            name
          } 
          categoryMulti {
            name
          }
          description
          duration
          image {
            url
          }
          longDescription {
            text
          }
          script {
            text
          }
          audio {
            fileName
            url # Assuming the Asset type also includes a URL field
          }
          multiplechoicequestion {
            id
            question
            choice 
            correctAnswer
            explanation
          }
        }
      }`
  const result = await request(MASTER_URL, query)
  return result
}*/