import React, { useContext, useState } from 'react';
import { AuthContext } from '../../firebase/AuthContext';
import { saveTitleForUser, removeTitleForUser } from '../../firebase/firestore';
import './TitleListItemSmall.css';

const TitleListItemSmall = ({ title, themeColors, themePreference }) => {
  const { user } = useContext(AuthContext);
  const [isSaved, setIsSaved] = useState(false);

  const handleSavePress = async () => {
    if (user) {
      if (isSaved) {
        await removeTitleForUser(user.uid, title.id);
        setIsSaved(false);

      } else {
        await saveTitleForUser(user.uid, title.id, {
          title: title.name,
          titleId: title.id,
          date: new Date(),
        });
        setIsSaved(true);

      }
    }
  };

  return (
    <div className="title-list-item-small">
      <img src={title?.image?.url} alt={title?.name} className="title-list-item-small-image" />
      <div className="title-list-item-small-details">
        <div className="title-list-item-small-name-fav">
          <h3 className="title-list-item-small-name" style={{ color: 'white' }}>{title?.name}</h3>
        </div>
        <p className="title-list-item-small-description" style={{ color: 'gray' }}>{title?.description}</p>
        <div className="title-list-item-small-category-duration">
          {title?.categoryMulti?.length > 0 && (
            <span className="title-list-item-small-category" style={{ backgroundColor: 'rgba(245, 222, 179, 0.3)', color: 'wheat' }}>{title.categoryMulti[0].name}</span>
          )}
          {/*<span className="title-list-item-small-duration" style={{ borderColor: themePreference === 'dark' ? 'rgba(245, 222, 179, 0.3)' : 'rgba(0, 0, 0, 0.2)', color: themePreference === 'dark' ? 'wheat' : 'black' }}>{title?.duration}</span>
          <button onClick={handleSavePress} className="title-list-item-small-save-button">
            <i className={`fa ${isSaved ? 'fa-bookmark' : 'fa-bookmark-o'}`} style={{ color: isSaved ? 'gold' : themeColors.textColor }}></i>
          </button>*/}
        </div>
      </div>
    </div>
  );
};

export default TitleListItemSmall;