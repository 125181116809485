import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy for Histo</h1>
      
      <section>
        <h2>1. Introduction</h2>
        <p>Welcome to Histo. This privacy policy explains how we collect, use, and protect your personal information when you use our mobile application. Histo is operated by an individual developer, not a company or LLC.</p>
      </section>

      <section>
        <h2>2. Information We Collect</h2>
        <p>We collect the following types of information:</p>
        <ul>
          <li>Personal Information: When you create an account, we may collect your name and email address.</li>
          <li>Usage Data: We collect information about how you interact with our app, including the audio biographies you listen to and your quiz responses.</li>
          <li>Device Information: We collect information about the device you use to access our app, including device type, operating system, and unique device identifiers.</li>
        </ul>
      </section>

      <section>
        <h2>3. How We Use Your Information</h2>
        <p>We use your information to:</p>
        <ul>
          <li>Provide and improve our services, including personalized audio biographies and quizzes.</li>
          <li>Process payments for subscriptions.</li>
          <li>Deliver non-personalized advertisements.</li>
          <li>Analyze app usage to improve user experience.</li>
          <li>Communicate with you about your account and app updates.</li>
        </ul>
      </section>

      <section>
        <h2>4. Advertising</h2>
        <p>We display non-personalized ads in the free version of our app. These ads do not use your personal information for targeting. Our paid subscription option removes ads entirely.</p>
      </section>

      <section>
        <h2>5. Data Sharing and Disclosure</h2>
        <p>We do not sell your personal information. We may share your information with:</p>
        <ul>
          <li>Service providers who help us operate our app (e.g., hosting, payment processing).</li>
          <li>Legal authorities when required by law.</li>
        </ul>
      </section>

      <section>
        <h2>6. Data Security</h2>
        <p>We implement reasonable security measures to protect your information. However, no method of electronic storage or transmission is 100% secure.</p>
      </section>

      <section>
        <h2>7. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access and update your personal information.</li>
          <li>Request deletion of your account and associated data.</li>
          <li>Opt-out of non-essential communications.</li>
        </ul>
      </section>

      <section>
        <h2>8. Changes to This Policy</h2>
        <p>We may update this privacy policy from time to time. We will notify you of any significant changes via email or through the app.</p>
      </section>

      <section>
        <h2>9. Contact Us</h2>
        <p>If you have any questions or concerns about this privacy policy, please contact us at contact@histochat.com.</p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;