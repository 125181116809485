import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import TitleListItemSmall from './TitleListItemSmall';
import { AuthContext } from '../../firebase/AuthContext';
import { fetchLatestTitleList } from './latestTitleListSlice';
import { useDispatch, useSelector } from 'react-redux';
import './LatestTitles.css'; // Import the CSS file
import { NextArrow, PrevArrow } from './CustomArrows';

const LatestTitles = ({ themeColors }) => {
    const navigate = useNavigate();
    const { themePreference } = useContext(AuthContext);
    const dispatch = useDispatch();
    const { data: latestTitleList, status: latestTitleListStatus } = useSelector((state) => state.latestTitleList);

    useEffect(() => {
        if (latestTitleListStatus === 'idle') {
            dispatch(fetchLatestTitleList());
        }
    }, [dispatch, latestTitleListStatus]);

    const memoizedLatestTitleList = useMemo(() => {
        return latestTitleList.slice(0, 16);
    }, [latestTitleList]);

    const handleSeeMorePress = useCallback(() => {
        const allLatestTitles = latestTitleList.slice(0, 50);
        navigate('/title-list', {
            state: {
                titles: allLatestTitles,
                screenTitle: 'Latest',
                themeColors: themeColors,
            },
        });
    }, [latestTitleList, navigate, themeColors]);

    const handleClick = (title) => {
        navigate(`/title-detail/${title.id}`, { state: { title, themeColors } });
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 4,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        draggable: false, // Disable dragging
        swipe: false, // Disable swipe
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="latest-titles-component">
            <div className="latest-titles-header">
                <h2 className="latest-titles-heading">Latest</h2>
                <button onClick={handleSeeMorePress} className="latest-titles-see-more">See More</button>
            </div>
            <Slider {...settings}>
                {memoizedLatestTitleList.map((title) => (
                    <div
                        key={title.id}
                        className="latest-title-item"
                        onClick={() => handleClick(title)}
                    >
                        <TitleListItemSmall title={title} themeColors={themeColors} themePreference={themePreference} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default LatestTitles;
